import React, { Component } from 'react';
import './Contact.css';

export default class Contact extends Component {
    render() {
        return (
            <div className="contact" id="contact">
                <h1>Contact</h1>
                {/*<p>Email: sameer@sameerkhan.ca</p>*/}
                <div className="icons">
                    <a href="https://www.linkedin.com/in/sameer-khan-508b9614b/" target='_blank' rel="noreferrer" className="square"><i className="fab fa-linkedin-in circle-icon icon-look"></i></a>
                    <a href="https://medium.com/@sameerkhan9" target='_blank' rel="noreferrer" className="square"><i className="fab fa-medium-m circle-icon icon-look"></i></a>
                    <a href="https://github.com/Sameer1223" target='_blank' rel="noreferrer" className="square"><i className="fab fa-github circle-icon icon-look"></i></a>
                </div>
            </div>
        )
    }
}
