import './Home.css';
import React, { Component } from 'react';

export default class Home extends Component {
    render() {
        return (
            <div className="rectangle" id="home">
                <img src="../images/elevatepres.jpeg" alt=""/>
                <h1>Sameer Khan</h1>
                <p>ML Enthusiast | CS Student | Software Developer </p>
                <div className="icons-home">
                    <a href="https://www.linkedin.com/in/sameer-khan-508b9614b/" target='_blank' rel="noreferrer" className="square"><i className="fab fa-linkedin-in circle-icon icon-look"></i></a>
                    <a href="https://medium.com/@sameerkhan9" target='_blank' rel="noreferrer" className="square"><i className="fab fa-medium-m circle-icon icon-look"></i></a>
                    <a href="https://github.com/Sameer1223" target='_blank' rel="noreferrer" className="square"><i className="fab fa-github circle-icon icon-look"></i></a>
                </div>
                <a href='#about' className="button">
                    <i className="fas fa-sort-down"></i> 
                </a>
            </div>
        )
    }
}
