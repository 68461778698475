import React from 'react';
import './Portfolio.css';

function PortfolioItem(props){
    const { item } = props;
    return(
        <div className="project">
            <a href={item.src} target="_blank" rel="noreferrer">
                <img src={item.img} alt='' />
            </a>
            <div className="cover" />
            <div className="text">
                <h1>{item.title}</h1>
                <p>{item.description}</p>
                <p>{item.tags}</p>
            </div>
        </div>
    )
}

export default PortfolioItem;
