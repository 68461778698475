import React, { Component } from 'react';
import './Experience.css';

export default class Experience extends Component {
    render() {
        return (
            <div className="experience" id="experience">
                <h1>Experience</h1>
                <div className="experience-card">
                <div className="imgSection"><img src='../images/caseware.png' style={{background: 'white'}} alt='' /></div>
                    <div className="textSection">
                        <h1>Caseware - Software Developer</h1>
                        <p>Co-op work term developing features for the accounting data analytics software. Primary focus on backend 
                            development in an Agile work environment writing software in Python, Java, and Typescript. Also participated in
                            research tasks to find improvements for Caseware code.
                        </p>
                    </div>
                </div>
                <div className="experience-card">
                <div className="imgSection"><img src='../images/flinkslogo.png' style={{background: 'white'}} alt='' /></div>
                    <div className="textSection">
                        <h1>Flinks - Software Engineer</h1>
                        <p>First co-op work term, working at Flinks, a financial tech company on building additions to their financial data API.
                            Built data connections to financial institutions with C#, .NET, Azure Devops technologies. Also participated in testing
                            and bug-fixing throughout the internship. Got a full experience of development from start to deployment.
                        </p>
                    </div>
                </div>
                <div className="experience-card">
                    <div className="imgSection"><img src='../images/netramark.jpg' alt=''/></div>
                    <div className="textSection">
                        <h1>Netramark (Pharma Startup) - Machine Learning Devloper</h1>
                        <p>Summer internship where I created a machine learning model (an Adversarial Autoencoder) using PyTorch that would take breast cancer cell line data, and
                            a set of effective drugs to generate novel drugs that have the potential of curing breast cancer. Used PyTorch, NCBI datasets, and Python
                            to complete the project.
                        </p>
                    </div>
                </div>
                <div className="experience-card">
                    <div className="imgSection"><img src='../images/CIBC-logo.png' alt='' /></div>
                    <div className="textSection">
                        <h1>CIBC - Digital Changemaker Intern</h1>
                        <p>Summer internship at CIBC Live Labs where I was tasked with creating proof of concepts for new technologies that CIBC is planning on looking into.
                            Developed an Augmented Reality app using Swift to gamify the experience of collection points on your credit card.
                            Presented the project in front of VPs of CIBC Digital and also spoke in a panel at the company town hall.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
