import React, { useState } from 'react';
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const [navbar, setNavbar] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const changeBackground = () => {
        if(window.scrollY >= 80) {
            setNavbar(true)
        } else{
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', changeBackground);

    return (
        <>
            <nav className={navbar ? 'navbar active' : 'navbar'}>
                <div className='navbar-container'>
                    <a href='#home' className='navbar-logo' onClick={closeMobileMenu}>
                        <img src='../images/sk.png' alt=''/>
                    </a>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <a href='#home' className={navbar ? 'nav-links active' : 'nav-links'} onClick={closeMobileMenu}>
                                Home
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a href='#about' className={navbar ? 'nav-links active' : 'nav-links'} onClick={closeMobileMenu}>
                                About
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a href='#experience' className={navbar ? 'nav-links active' : 'nav-links'} onClick={closeMobileMenu}>
                                Experience
                            </a>
                        </li>
                        <li>
                            <a href='#portfolio' className={navbar ? 'nav-links active' : 'nav-links'} onClick={closeMobileMenu}>
                                Portfolio
                            </a>
                        </li>
                        <li>
                            <a href='#articles' className={navbar ? 'nav-links active' : 'nav-links'} onClick={closeMobileMenu}>
                                Articles
                            </a>
                        </li>
                        <li>
                            <a href='#contact' className={navbar ? 'nav-links active' : 'nav-links'} onClick={closeMobileMenu}>
                                Contact
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Navbar;