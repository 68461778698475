import React, { useState, useMemo } from 'react';
import PortfolioItem from "./PortfolioItem";
import './Portfolio.css';

const list = [
    {
        id: 1,
        title: "Ferrous Unity Puzzle Game",
        src:"https://sameerk9.itch.io/ferrous",
        img: '../images/ferrous.png',
        description:"A magnetism based puzzle game built in Unity with a team of programmers, musicians, and artists.",
        tags: "Unity, C#",
        category:"Projects",
    },
    {
        id: 2,
        title: "Sportcred Mobile Application",
        src: "https://github.com/Sameer1223/sportcredMobileApp",
        img: '../images/sportcred.png',
        description: "An app where you can compete in sports trivia and predictions developed by a team of 6 students. I contributed to the front-end.",
        tags: "Flutter, RestAPI",
        category: "Projects"
    },
    {
        id: 3,
        title: "Elevate AI Conference Presentation",
        src: "https://www.youtube.com/watch?v=Cdbrbi1x-Sg&feature=youtu.be&ab_channel=SameerKhan",
        img: '../images/elevatepres.jpeg',
        description: "AI: Creativity and Intersections with Emerging Technologies",
        tags: "Elevate AI",
        category: "Presentations"
    },
    {
        id: 4,
        title: "Mock Linux Terminal with Mock Filesystem",
        src: "https://github.com/Sameer1223/mockLinuxTerminal",
        img: '../images/mocklinuxshell.png',
        description: "A mock linux shell with functional commands and mock file system using OOP and developed using Agile methods",
        tags: "Java, OOP",
        category: "Projects"
    },
    {
        id: 5,
        title: "2D Virtual Self Driving Car using DRL",
        src: "https://github.com/Sameer1223/virtual_self_driving_car_drl",
        img: '../images/7.jpg',
        description: "A 2D car that learns how to drive from point A to point B avoiding any obstacles in its way using virtual sensors",
        tags: "Q-Learning, RL",
        category: "Projects"
    },
    {
        id: 6,
        title: "Presentation on Generative Adversarial Networks",
        src: "https://www.youtube.com/watch?v=EPlksF-FGP0&ab_channel=TheKnowledgeSociety",
        img: '../images/2.jpg',
        description: "My presentation on Generative Adversarial Networks",
        tags: "TKS Showcase",
        category: "Presentations"
    },
    {
        id: 7,
        title: "Stock Price Prediction using RNNs and LSTMs",
        src: "https://github.com/Sameer1223/stockprice_rnn",
        img: '../images/4.jpg',
        description: "A Recurrent Neural Network using Long Short Term Memory to predict real stock prices by analyzing recent data",
        tags: "RNNs, LSTMs",
        category: "Projects"
    },
    {
        id: 8,
        title: "Image Generation with GANs",
        src: "https://github.com/Sameer1223/dcgan",
        img: '../images/3.jpg',
        description: "Using Generative Adversarial Network to create completely new images based on existing photographs",
        tags: "GANs, PyTorch",
        category: "Projects"
    },
    {
        id: 9,
        title: "Cancer Classification Neural Network",
        src: "https://github.com/Sameer1223/breast_cancer_classification",
        img: '../images/1.jpg',
        description: "Artificial Neural Network that is able to classify tumours using numerical data as benign or malignant",
        tags: "Neural Network",
        category: "Projects"
    },
    {
        id: 10,
        title: "3D First Person Shooter Game",
        src: "https://github.com/Sameer1223/FPSPracticeRange",
        img: '../images/fpsgame.png',
        description: "A 3D First Person Shooter pratice range with abilities based on characters from Overwatch built in Unity",
        tags: "Unity, C#",
        category: "Projects"
    },
    {
        id: 11,
        title: "Neural Networks made Easy",
        src: "https://www.linkedin.com/pulse/neural-networks-made-easy-sameer-khan/",
        img: '../images/5.jpg',
        description: "The basics of a neural network and how they function",
        tags: "ML, Article",
        category: "Articles"
    },
    {
        id: 12,
        title: "The Advantage of A3C Models",
        src: "https://medium.com/@sameerkhan9/the-advantage-of-the-asynchronous-actor-critic-algorithm-d15f5afbf52a",
        img: '../images/10.png',
        description: "Asynchronous Actor-Critic Agents. What they are how they work.",
        tags: "RL, Article",
        category: "Articles"
    },
    {
        id: 13,
        title: "Learnings from San Francisco",
        src: "https://www.linkedin.com/pulse/what-i-learned-from-my-life-changing-trip-san-francisco-sameer-khan/",
        img: '../images/11.jpg',
        description: "Learnings from my first business conference in San Francisco",
        tags: "Blog",
        category: "Articles"
    }
];

function Portfolio(){
    const [filter, setFilter] = useState('All')

    const filteredPortfolio = useMemo(() => {
        if (filter === 'All') return list

        return list.filter(portfolio => portfolio.category === filter)
    }, [filter])

    function setActive(category){
        var btns = document.getElementsByClassName("filter");

        for (var i = 0; i < btns.length; i++){
            if (btns[i].className === "filter active"){
                btns[i].className = "filter";
            }
            if (btns[i].innerHTML === category){
                btns[i].className = "filter active";
            }
        }
    }

    return (
        <div className="portfolio" id="portfolio">
            <h1>Portfolio</h1>
            <div className="selector">
                <button onClick={() => {setFilter('All'); setActive('All')}} className="filter active">All</button>
                <button onClick={() => {setFilter('Projects'); setActive('Projects')}} className="filter">Projects</button>
                <button onClick={() => {setFilter('Articles'); setActive('Articles')}} className="filter">Articles</button>
                <button onClick={() => {setFilter('Presentations'); setActive('Presentations')}} className="filter">Presentations</button>
            </div>
            <div className="items">
                {filteredPortfolio.map(item => (
                    <PortfolioItem key={item.id} item={item}/>
                ))}
            </div>
        </div>
    );
}


export default Portfolio;