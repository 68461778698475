import './App.css';
import Navbar from './Navbar';
import Home from './Home';
import About from './About'; 
import Experience from './Experience';
import Portfolio from './Portfolio';
import Blog from './Blog';
import Contact from './Contact';

function App() {
  return (
    <>
      <Navbar/>
      <Home/>
      <About/>
      <Experience/>
      <Portfolio/>
      <Blog/>
      <Contact/>
    </>
  );
}

export default App;

/*
To Fix:

1. Navbar centering DONE
2. Logo height mismatch with bars DONE
3. Image fits to screen not cropping DONE
4. Links route to another page instead of staying on same page DONE
5. Navbar transparent when bars are clicked DONE
6. Little edge on the right side of navbar DONE
7. Border for navbar so its not bad white on white DONE
8. Navbar links dont smooth scroll DONE
9. Navbar links dont account for navbar height DONE
10. Icons on homepage have weird click zones DONE
11. Grid has weird centering
12. Make site resposnsive
*/ 