import React, { Component } from 'react';
import './Blog.css';

export default class Blog extends Component {
    render() {
        return (
            <div className="blog" id="articles">
                <h1 className="title">Articles</h1>
                <div className="card-container">
                    <div className="card">
                        <img src="../images/molecules.jpg" alt=''/>
                        <h1>Machine Learning is Going To Disrupt The Pharma Industry</h1>
                        <p>The current drug discovery process is incredibly slow. It takes countless years to develop a successful candidate.</p>
                        <p>Machine learning will soon allow us to develop new drugs and find new cures at an astronomical scale.</p>
                        <a href="https://medium.com/datadriveninvestor/machine-learning-is-going-to-disrupt-the-pharmaceutical-industry-heres-how-250cafb3ad00" target="_blank" rel="noreferrer">Read More</a>
                    </div>
                    <div className="card">
                        <img src="../images/gans.jpg" alt='' />
                        <h1>Generative Adversarial Networks: Image Generation</h1>
                        <p>Generative Adversarial Networks have the ability to create data opposed to analyzing as other neural networks do.</p>
                        <p>In this article I explain how GANs can be creative, its applications, and an example I coded.</p>
                        <a href="https://medium.com/@sameerkhan9/generative-adversarial-networks-what-they-are-their-applications-and-my-gan-496c00c49349" target="_blank" rel="noreferrer">Read More</a>
                    </div>
                    <div className="card">
                        <img src="../images/alphago.jpg" alt='' />
                        <h1>Reinforcement Learning: Deep Q-Learning</h1>
                        <p>Humans learn by reinforcement from the time they are born to now. So why not teach machines in the same way?</p>
                        <p>Reinforcement learning has become a method to train machines to do things just as good as humans if not better.</p>
                        <a href="https://medium.com/@sameerkhan9/reinforcement-learning-deep-q-learning-749f212fc73b" target="_blank" rel="noreferrer">Read More</a>
                    </div>
                </div>
            </div>
        )
    }
}
