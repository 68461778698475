import React, { Component } from 'react'
import './About.css';

export default class About extends Component {
    render() {
        return (
            <div className="about" id="about" name="about">
                <div className="pic">
                    <img src='../images/sameerProfilePic.png' alt=''/>
                </div>
                <div className="meText">
                    <h1>About Me</h1>
                    <p>Hey! I'm Sameer and I'm a 21 year old fourth-year computer science student at the University of Toronto.
                        I've had a passion for technology for the longest time and love learning about new things.
                    </p>
                    <p>
                    I'm very passionate about machine learning as I believe it will bring significant advancements to civilization.
                    I've worked on projects in the space and even have worked an internship to apply the technology. I also love 
                    game development and software development and work on projects in my free time.
                    My hobbies include playing video games, learning about technology, and watching Formula 1 Racing. </p>
                    <p>
                        My ultimate goal is to build my own startup company that solves a meaningul problem so that I can
                        impact millions or even billions of people. I have fun learning about business and how to run companies along
                        side learning software development. I believe pairing technology and business has become essential and I strive
                        to build a company that can pair those two elements with something in biology so that I can achieve my dream
                        of changing lives.
                    </p>
                </div>
            </div>
        )
    }
}
